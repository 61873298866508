/*
 * @Author: fredwei
 * @Date: 2019-03-08 11:57:14
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-03-26 17:42:26
 */


// 关闭弹出框的定时器
var closeTime = false;
var _resizeTime;

// 计算可滚动区域的最大高度
function countScrollMaxHeight($dialogMain) {
    var winHeight = $(window).height();
    var dhHeight = $dialogMain.find('.dialog_head').outerHeight();
    var dfHeight = $dialogMain.find('.dialog_footer').outerHeight();

    // console.log(dhHeight, dfHeight);

    $dialogMain.find('.dialog_body').css('max-height', parseInt(winHeight * 0.9 - dhHeight - dfHeight, 10));
}

// 打开弹出框
function open(opts) {
    var _opts = $.extend({}, {
        // 标题
        title: '提示',
        // 内容
        content: '',
        // 底部，可传入html字符串
        footer: true,
        // 容器class
        className: '',
        // 右上角关闭按钮
        closeBtn: true,
        // 关闭按钮的图标class
        closeClass: 'iconclose',
        // 遮罩层点击关闭
        maskClose: true,
        // 弹出框内容区域是否可以滚动
        isScroll: false,
        // 显示后回调方法
        callback: null
    }, opts);

    var $dialog = $('#dialog');
    var _dialogHtml = '';

    if (!$dialog.length) {
        $dialog = $('<div id="dialog" class="dialog"></div>');

        $('body').append($dialog);

        // 关闭
        $dialog.on('click', '.js_dialog_close', function () {
            close();

            return false;
        });

        // 浏览器窗口变动
        $(window).on('resize', function () {
            if ($dialog.is(':hidden')) {
                return;
            }

            clearTimeout(_resizeTime);

            _resizeTime = setTimeout(function () {
                countScrollMaxHeight($dialog.find('.dialog_main:visible'));
            }, 300);
        });
    }

    if ($dialog.find('.dialog_mask').length || $dialog.find('.dialog_main').length) {
        // 移除被选元素的数据和事件
        $dialog.find('.dialog_mask,.dialog_main').remove();
    }


    _dialogHtml += '<div class="dialog_mask ' + (_opts.maskClose ? 'js_dialog_close' : '') + '"></div>';

    _dialogHtml += '<div class="dialog_main ' + _opts.className + '">';
    // _dialogHtml += '<div class="dialog_head clearfix"><span>' + _opts.title + '</span>' + (_opts.closeBtn ? '<a href="javascript:void(0);" class="dialog_btn_close js_dialog_close"><span class="iconfont ' + _opts.closeClass + '"></span></a>' : '') + '</div>';
    _dialogHtml += '<div class="dialog_head clearfix"><span>' + _opts.title + '</span>' + (_opts.closeBtn ? '<a href="javascript:void(0);" class="dialog_btn_close js_dialog_close"><span></span></a>' : '') + '</div>';
    _dialogHtml += '<div class="dialog_body clearfix">' + _opts.content + '</div>';

    if (_opts.footer && typeof (_opts.footer) !== 'string') {
        _dialogHtml += '<div class="dialog_footer clearfix">';
        _dialogHtml += '<button type="button" class="cancel js_dialog_close">取消</button>';
        _dialogHtml += '<button type="button" class="confirm primary">确定</button>';
        _dialogHtml += '</div>';
    } else if (typeof (_opts.footer) === 'string') {
        _dialogHtml += '<div class="dialog_footer">';
        _dialogHtml += _opts.footer;
        _dialogHtml += '</div>';
    }

    _dialogHtml += '</div>';

    $dialog.html(_dialogHtml);

    if (_opts.isScroll) {
        countScrollMaxHeight($dialog.find('.dialog_main'));

        $dialog.addClass('dialog_scroll');

        $('body').css('overflow', 'hidden');
    }

    if ($dialog.is(':hidden')) {
        $dialog.fadeIn(function () {
            if (_opts.isScroll) {
                countScrollMaxHeight($dialog.find('.dialog_main'));
            }

            _opts.callback && _opts.callback($dialog);
        });
    } else {
        _opts.callback && _opts.callback($dialog);
    }
}

// 关闭弹出框
function close(opts) {
    var _opts = $.extend({}, {
        // 定时关闭
        interval: 0,
        // 定时关闭执行中回调
        intervalCallback: null,
        // 关闭后回调
        callback: null
    }, opts);

    var $dialog = $('#dialog');

    if (!$dialog.length) {
        // console.log('notfound dialog main');
        return false;
    }

    // 关闭操作
    function hideDialog() {
        $('body').css('overflow', '');

        $dialog.fadeOut(function () {
            // 移除被选元素的数据和事件
            $dialog.find('.dialog_mask,.dialog_main').remove();

            _opts.callback && _opts.callback($dialog);
        }).removeClass('dialog_scroll');
    }

    // 定时关闭操作
    function timeOut() {
        if (!closeTime) {
            return;
        }

        _opts.intervalCallback && _opts.intervalCallback($dialog, _opts.interval);

        setTimeout(function () {
            --_opts.interval;

            if (_opts.interval <= 0) {
                closeTime = false;
                hideDialog();
            } else {
                timeOut();
            }
        }, 1000);
    }

    // 倒计时关闭
    if (_opts.interval) {
        closeTime = true;
        timeOut();
    } else {
        closeTime = false;
        hideDialog();
    }
}

// 轻提示
function toast(opts) {
    var _opts = $.extend({}, {
        // 提示类型 成功 success、失败 error、提醒 warning
        type: '',
        // 提示标题
        title: '提示',
        // 内容
        content: '',
        // 容器class
        className: 'dialog_toast',
        // 是否是短信息，如只有一行文字
        isShort: true,
        // 是否显示关闭按钮
        closeBtn: true,
        // 隐藏底部
        footer: false,
        // 是否 倒计时 隐藏，单位为秒，不为0时则表示提示框自动关闭
        interval: 0,
        // 倒计时回调
        intervalCallback: null,
        // 显示后回调方法
        callback: null
    }, opts);

    var $dialog = $('#dialog');
    var runTime = null;
    var _paddingTop = 4;

    // 内容是否简短
    if (_opts.isShort) {
        _paddingTop = 15;
    }

    // 提示类型 成功、失败、提醒
    _opts.content = '<div class="tips ' + _opts.type + '" style="padding-top: ' + _paddingTop + 'px;">' + _opts.content + '</div>';

    open(_opts);

    // 定时操作
    function timeRun() {
        clearTimeout(runTime);

        _opts.intervalCallback && _opts.intervalCallback($dialog, _opts.interval);

        runTime = setTimeout(function () {
            --_opts.interval;

            if (_opts.interval > 0) {
                timeRun();
            }
        }, 1000);
    }

    // 倒计时关闭
    if (_opts.interval && _opts.interval > 0) {
        timeRun();
    }
}

// 图片预览
function preview($box) {
    var winWidth = $(window).width();
    var winHeight = $(window).height();

    // 图片预览-单张
    $box.on('click', '.js_img_preview', function () {
        var imgSrc = $(this).attr('data-src') || $(this).attr('src');
        var imgWidth = $(this).attr('data-width');
        var imgHeight = $(this).attr('data-height');

        open({
            title: '',
            content: '<img src="" alt="">',
            footer: false,
            className: 'dialog_imgPreview',
            closeClass: 'iconclose-circle-fill',
            callback: function ($dialog) {
                var imgObj = new Image();
                var imgRatio = 0;

                imgObj.src = imgSrc;

                // console.log(imgSrc);

                imgObj.onload = function () {
                    imgWidth = imgWidth ? parseInt(imgWidth, 10) : imgObj.width;
                    imgHeight = imgHeight ? parseInt(imgHeight, 10) : imgObj.height;

                    // console.log(imgRatio, imgWidth, imgHeight);

                    // 判断图片尺寸是否超过屏幕宽高
                    if (imgWidth >= winWidth || imgHeight >= winHeight) {
                        imgRatio = imgWidth / imgHeight;

                        // 图片宽高比
                        if (imgRatio < 1) {
                            // 竖图
                            // $dialog.find('img').addClass('img-vertical');
                            imgHeight = parseInt(winHeight * 0.9, 10);
                            imgWidth = parseInt(imgHeight * imgRatio, 10);
                        } else {
                            // 横图
                            imgWidth = parseInt(winWidth * 0.9, 10);
                            imgHeight = parseInt(imgWidth / imgRatio, 10);
                        }

                        // console.log(imgRatio, imgWidth, imgHeight);
                    }

                    $dialog.find('img').attr('src', imgSrc);

                    setTimeout(function () {
                        $dialog.find('.dialog_main').css({
                            width: imgWidth,
                            height: imgHeight,
                        }).addClass('loaded').find('img').css('opacity', 1);
                    }, 30);
                };

                // imgObj.onerror = function (err) {
                // console.log(err);
                // };
            }
        });
    });
}

module.exports = {
    open: open,
    close: close,
    toast: toast,
    preview: preview
};
