/*
页面初始化，每个页面都必须引入
*/
// cookie操作
var mdCookie = require('./md.cookie');

// 多语言
// var mdI18n = require('./md.i18n');
// 弹出框
// var mdDialog = require('./md.dialog');
// 提示
// var mdToast = require('./md.toast');
// 登录注册模块
// const mdAccount = require('./md.account');

// 用于加密传递的值
const GlobalConfig = {
    cftoken: $('meta[name="csrf-token"]').attr('content') || '',
    winWidth: 0,
    winHeight: 0,
};

// 页面初始化完成
function init(callback) {
    var $header = $('#header');

    // 设置请求头
    // $.ajaxSetup({
    //     headers: {
    //         'X-CSRF-TOKEN': GlobalConfig.cftoken
    //     }
    // });

    // 获取窗口大小
    GlobalConfig.winWidth = $(window).width();
    GlobalConfig.winHeight = $(window).height();

    // // 多语言
    // mdI18n.init(function (lang) {
    //     $('body').addClass(lang);

    //     $header.find('.lang li[data-type="' + lang + '"]').addClass('active');
    // });

    // // 多语言切换
    // $header.on('click', '.lang li', function() {
    //     var type = $(this).attr('data-type');

    //     mdCookie.set('taurusLang', type);

    //     $(this).addClass('active').siblings().removeClass('active');

    //     mdI18n.init((lang) => {
    //         $('body').removeClass('en zh').addClass(lang);
    //     });
    // });

    // console.log($('[data-i18n]'));

    // mdToast.open({
    //     content: '你好，今天天气很不错',
    //     showClose: true,
    //     duration: 6,
    //     onClose: function () {
    //         console.log('关闭');
    //     }
    // });

    // setTimeout(function () {
    //     mdToast.open({
    //         type: 'error',
    //         content: '你好，今天天气很不好',
    //         showClose: true,
    //         onClose: function () {
    //             console.log('关闭');
    //         }
    //     });
    // }, 2000);

    // mdAccount.init();

    typeof (callback) === 'function' && callback(GlobalConfig);
}

module.exports = {
    // 页面初始化
    init: init
};
